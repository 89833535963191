import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import BlogPostThumbnail from "../components/BlogPostThumbnail"

import RightArrow from "../images/arrow-right.svg"
import LeftArrow from "../images/arrow-left.svg"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="All posts" />

      <div className="container ">
        <div className="">
          {previousPagePath === null ? (
            <React.Fragment>
              <h3 className="text-gray-900 display-xs font-semibold mb-8">
                Recent Posts
              </h3>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-1">
                <div>
                  <Link className="blog-thumbnail-link" to={posts[0].uri}>
                    <img
                      className="mb-6"
                      src={
                        posts[0].featuredImage.node.mediaDetails.sizes[2]
                          .sourceUrl
                      }
                      alt=""
                    />
                    <div className="">
                      <h2 className="text-gray-900 display-xs font-semibold mb-3">
                        {posts[0].title}
                      </h2>
                      <div className="mb-6 text-md max-w-2xl">
                        {parse(posts[0].excerpt)}
                      </div>
                      <div>
                        {/*posts[0].categories.map(category => {
                          return <p>{category.name}</p>
                        })*/}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="lg:ml-auto">
                  <Link className="flex sm:flex-row flex-col" to={posts[1].uri}>
                    <img
                      className="mb-6 mr-3"
                      src={
                        posts[1].featuredImage.node.mediaDetails.sizes[4]
                          .sourceUrl
                      }
                      alt=""
                    />
                    <div className="max-w-xs">
                      <h2 className="text-gray-900 display-xs font-semibold mb-3">
                        {posts[1].title}
                      </h2>
                      <div className="mb-6 text-md">
                        {parse(posts[1].excerpt)}
                      </div>
                      <div>
                        {/*posts[0].categories.map(category => {
                          return <p>{category.name}</p>
                        })*/}
                      </div>
                    </div>
                  </Link>
                  <Link className="flex sm:flex-row flex-col" to={posts[2].uri}>
                    <img
                      className="mb-6 mr-3"
                      src={
                        posts[2].featuredImage.node.mediaDetails.sizes[4]
                          .sourceUrl
                      }
                      alt=""
                    />
                    <div className="max-w-xs">
                      <h2 className="text-gray-900 display-xs font-semibold mb-3">
                        {posts[2].title}
                      </h2>
                      <div className="mb-6 text-md">
                        {parse(posts[2].excerpt)}
                      </div>
                      <div>
                        {/*posts[0].categories.map(category => {
                          return <p>{category.name}</p>
                        })*/}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <h3 className="text-gray-900 display-xs font-semibold mb-8">
                All Blog Posts
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-3">
                {posts.slice(3).map((post, index) => {
                  return (
                    <BlogPostThumbnail
                      key={index}
                      blogLink={post.uri}
                      blogTitle={post.title}
                      blogExcerpt={post.excerpt}
                      categories={post.categories.nodes}
                      imageURL={
                        post.featuredImage.node.mediaDetails.sizes[5].sourceUrl
                      }
                    />
                  )
                })}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 className="text-gray-900 display-xs font-semibold mb-8">
                All Blog Posts
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-3">
                {posts.map((post, index) => {
                  return (
                    <BlogPostThumbnail
                      key={index}
                      blogLink={post.uri}
                      blogTitle={post.title}
                      blogExcerpt={post.excerpt}
                      categories={post.categories.nodes}
                      imageURL={
                        post.featuredImage.node.mediaDetails.sizes[5].sourceUrl
                      }
                    />
                  )
                })}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="container flex row my-20 text-gray-500 items-center justify-between">
        {previousPagePath && (
          <>
            <Link
              className="flex row text-gray-500 items-center"
              to={previousPagePath}
            >
              <img className="mr-2" src={LeftArrow} alt="left arrow" />
              Previous page
            </Link>
            <br />
          </>
        )}
        {nextPagePath && (
          <Link
            className="flex row text-gray-500 items-center"
            to={nextPagePath}
          >
            Next page{" "}
            <img className="ml-2" src={RightArrow} alt="right arrow" />
          </Link>
        )}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            sourceUrl
            altText
            mediaDetails {
              sizes {
                fileSize
                sourceUrl
              }
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
      }
    }
  }
`
